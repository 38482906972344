import { isEqual, transform } from 'lodash';

export const isDefined = obj => typeof obj !== 'undefined';

export const isNumber = obj => typeof obj === 'number';

export const isString = obj => typeof obj === 'string';

export const isObject = obj => obj !== null && typeof obj === 'object';

export const isArray = Array.isArray;

export const isArrayHasData = arr => isArray(arr) && !!arr.length;

export const isObjHasData = obj =>
  Boolean(obj) && typeof obj === 'object' && !!Object.keys(obj).length;

export const isDate = obj =>
  Object.prototype.toString.call(obj) === '[object Date]';

export const isFunction = obj => typeof obj === 'function';

export const isWindow = obj => obj && obj.window === obj;

export const isRegExp = value =>
  Object.prototype.toString.call(value) === '[object RegExp]';

export const isBlankObject = value => {
  let key;
  for (key in value) {
    if (value.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const isTypedArray = obj => {
  const TYPED_ARRAY_REGEXP = /^\[object (?:Uint8|Uint8Clamped|Uint16|Uint32|Int8|Int16|Int32|Float32|Float64)Array\]$/;
  return (
    obj &&
    isNumber(obj.length) &&
    TYPED_ARRAY_REGEXP.test(Object.prototype.toString.call(obj))
  );
};

export const isArrayBuffer = obj =>
  Object.prototype.toString.call(obj) === '[object ArrayBuffer]';

export const isArrayLike = obj => {
  // `null`, `undefined` and `window` are not array-like
  if (obj === null || isWindow(obj)) {
    return false;
  }

  // arrays, strings
  if (isArray(obj) || isString(obj)) {
    return true;
  }

  // Support: iOS 8.2 (not reproducible in simulator)
  // "length" in obj used to prevent JIT error (gh-11508)
  var length = 'length' in Object(obj) && obj.length;

  // NodeList objects (with `item` method) and
  // other objects with suitable length characteristics are array-like
  return (
    isNumber(length) &&
    ((length >= 0 && (length - 1 in obj || obj instanceof Array)) ||
      typeof obj.item === 'function')
  );
};

export const isElement = node =>
  !!(
    node &&
    (node.nodeName || // We are a direct element.
      (node.prop && node.attr && node.find))
  ); // We have an on and find method part of jQuery API.

export const baseExtend = (dst, objs, deep) => {
  var h = dst.$$hashKey;

  for (var i = 0, ii = objs.length; i < ii; ++i) {
    var obj = objs[i];
    if (!isObject(obj) && !isFunction(obj)) {
      continue;
    }
    var keys = Object.keys(obj);
    for (var j = 0, jj = keys.length; j < jj; j++) {
      var key = keys[j];
      var src = obj[key];

      if (deep && isObject(src)) {
        if (isDate(src)) {
          dst[key] = new Date(src.valueOf());
        } else if (isRegExp(src)) {
          dst[key] = new RegExp(src);
        } else if (src.nodeName) {
          dst[key] = src.cloneNode(true);
        } else if (isElement(src)) {
          dst[key] = src.clone();
        } else {
          if (!isObject(dst[key])) {
            dst[key] = isArray(src) ? [] : {};
          }
          baseExtend(dst[key], [src], true);
        }
      } else {
        dst[key] = src;
      }
    }
  }

  setHashKey(dst, h);
  return dst;
};

/**
 * @name extend
 * @module ng
 * @kind function
 *
 * @description
 * Extends the destination object `dst` by copying own enumerable properties from the `src` object(s)
 * to `dst`. You can specify multiple `src` objects. If you want to preserve original objects, you can do so
 * by passing an empty object as the target: `var object = extend({}, object1, object2)`.
 *
 * **Note:** Keep in mind that `extend` does not support recursive merge (deep copy). Use
 * {@link deepExtend} for this.
 *
 * @param {Object} dst Destination object.
 * @param {...Object} src Source object(s).
 * @returns {Object} Reference to `dst`.
 */
export const extend = (dst, ...args) =>
  baseExtend(dst, [].slice.call(args), false);

/**
 * @ngdoc function
 * @name merge
 * @module ng
 * @kind function
 *
 * @description
 * Deeply extends the destination object `dst` by copying own enumerable properties from the `src` object(s)
 * to `dst`. You can specify multiple `src` objects. If you want to preserve original objects, you can do so
 * by passing an empty object as the target: `var object = deepExtend({}, object1, object2)`.
 *
 * Unlike {@link extend extend()}, `deepExtend()` recursively descends into object properties of source
 * objects, performing a deep copy.
 *
 * @param {Object} dst Destination object.
 * @param {...Object} src Source object(s).
 * @returns {Object} Reference to `dst`.
 */
export const deepExtend = (dst, ...args) =>
  baseExtend(dst, [].slice.call(args), true);

export const forEach = (obj, iterator, context) => {
  var key, length;
  if (obj) {
    if (isFunction(obj)) {
      for (key in obj) {
        // Need to check if hasOwnProperty exists,
        // as on IE8 the result of querySelectorAll is an object without a hasOwnProperty function
        if (
          key !== 'prototype' &&
          key !== 'length' &&
          key !== 'name' &&
          (!obj.hasOwnProperty || obj.hasOwnProperty(key))
        ) {
          iterator.call(context, obj[key], key, obj);
        }
      }
    } else if (isArray(obj) || isArrayLike(obj)) {
      var isPrimitive = typeof obj !== 'object';
      for (key = 0, length = obj.length; key < length; key++) {
        if (isPrimitive || key in obj) {
          iterator.call(context, obj[key], key, obj);
        }
      }
    } else if (obj.forEach && obj.forEach !== forEach) {
      obj.forEach(iterator, context, obj);
    } else if (isBlankObject(obj)) {
      // createMap() fast path --- Safe to avoid hasOwnProperty check because prototype chain is empty
      for (key in obj) {
        iterator.call(context, obj[key], key, obj);
      }
    } else if (typeof obj.hasOwnProperty === 'function') {
      // Slow path for objects inheriting Object.prototype, hasOwnProperty check needed
      for (key in obj) {
        if (obj.hasOwnProperty(key)) {
          iterator.call(context, obj[key], key, obj);
        }
      }
    } else {
      // Slow path for objects which do not have a method `hasOwnProperty`
      for (key in obj) {
        if (hasOwnProperty.call(obj, key)) {
          iterator.call(context, obj[key], key, obj);
        }
      }
    }
  }
  return obj;
};

export const equals = (o1, o2) => {
  if (o1 === o2) {
    return true;
  }
  if (o1 === null || o2 === null) {
    return false;
  }
  if (o1 !== o1 && o2 !== o2) {
    return true;
  } // NaN === NaN
  var t1 = typeof o1,
    t2 = typeof o2,
    length,
    key,
    keySet;
  if (t1 === t2 && t1 === 'object') {
    if (isArray(o1)) {
      if (!isArray(o2)) {
        return false;
      }
      if ((length = o1.length) === o2.length) {
        for (key = 0; key < length; key++) {
          if (!equals(o1[key], o2[key])) {
            return false;
          }
        }
        return true;
      }
    } else if (isDate(o1)) {
      if (!isDate(o2)) {
        return false;
      }
      return equals(o1.getTime(), o2.getTime());
    } else if (isRegExp(o1)) {
      if (!isRegExp(o2)) {
        return false;
      }
      return o1.toString() === o2.toString();
    } else {
      if (
        isWindow(o1) ||
        isWindow(o2) ||
        isArray(o2) ||
        isDate(o2) ||
        isRegExp(o2)
      ) {
        return false;
      }
      keySet = Object.create(null);
      for (key in o1) {
        if (key.charAt(0) === '$' || isFunction(o1[key])) {
          continue;
        }
        if (!equals(o1[key], o2[key])) {
          return false;
        }
        keySet[key] = true;
      }
      for (key in o2) {
        if (
          !(key in keySet) &&
          key.charAt(0) !== '$' &&
          o2[key] &&
          !isFunction(o2[key])
        ) {
          return false;
        }
      }
      return true;
    }
  }
  return false;
};
export const copy = (source, destination) => {
  var stackSource = [];
  var stackDest = [];

  if (destination) {
    if (isTypedArray(destination) || isArrayBuffer(destination)) {
      throw Error("Can't copy! TypedArray destination cannot be mutated.");
    }
    if (source === destination) {
      throw Error("Can't copy! Source and destination are identical.");
    }

    // Empty the destination object
    if (isArray(destination)) {
      destination.length = 0;
    } else {
      forEach(destination, (value, key) => {
        if (key !== '$$hashKey') {
          delete destination[key];
        }
      });
    }

    stackSource.push(source);
    stackDest.push(destination);
    return copyRecurse(source, destination);
  }

  return copyElement(source);

  function copyRecurse(source, destination) {
    var h = destination.$$hashKey;
    var key;
    if (isArray(source)) {
      for (var i = 0, ii = source.length; i < ii; i++) {
        destination.push(copyElement(source[i]));
      }
    } else if (isBlankObject(source)) {
      // createMap() fast path --- Safe to avoid hasOwnProperty check because prototype chain is empty
      for (key in source) {
        destination[key] = copyElement(source[key]);
      }
    } else if (source && typeof source.hasOwnProperty === 'function') {
      // Slow path, which must rely on hasOwnProperty
      for (key in source) {
        if (source.hasOwnProperty(key)) {
          destination[key] = copyElement(source[key]);
        }
      }
    } else {
      // Slowest path --- hasOwnProperty can't be called as a method
      for (key in source) {
        if (hasOwnProperty.call(source, key)) {
          destination[key] = copyElement(source[key]);
        }
      }
    }
    setHashKey(destination, h);
    return destination;
  }

  function copyElement(source) {
    // Simple values
    if (!isObject(source)) {
      return source;
    }

    // Already copied values
    var index = stackSource.indexOf(source);
    if (index !== -1) {
      return stackDest[index];
    }

    if (isWindow(source)) {
      throw Error("Can't copy! Making copies of Window is not supported.");
    }

    var needsRecurse = false;
    var destination = copyType(source);

    if (destination === undefined) {
      destination = isArray(source)
        ? []
        : Object.create(Object.getPrototypeOf(source));
      needsRecurse = true;
    }

    stackSource.push(source);
    stackDest.push(destination);

    return needsRecurse ? copyRecurse(source, destination) : destination;
  }

  function copyType(source) {
    switch (Object.prototype.toString.call(source)) {
      case '[object Int8Array]':
      case '[object Int16Array]':
      case '[object Int32Array]':
      case '[object Float32Array]':
      case '[object Float64Array]':
      case '[object Uint8Array]':
      case '[object Uint8ClampedArray]':
      case '[object Uint16Array]':
      case '[object Uint32Array]':
        return new source.constructor(copyElement(source.buffer));

      case '[object ArrayBuffer]':
        //Support: IE10
        if (!source.slice) {
          var copied = new ArrayBuffer(source.byteLength);
          new Uint8Array(copied).set(new Uint8Array(source));
          return copied;
        }
        return source.slice(0);

      case '[object Boolean]':
      case '[object Number]':
      case '[object String]':
      case '[object Date]':
        return new source.constructor(source.valueOf());

      case '[object RegExp]':
        var re = new RegExp(
          source.source,
          source.toString().match(/[^\/]*$/)[0]
        );
        re.lastIndex = source.lastIndex;
        return re;

      case '[object Blob]':
        return new source.constructor([source], { type: source.type });
    }

    if (isFunction(source.cloneNode)) {
      return source.cloneNode(true);
    }
  }
};

export const strPad = (value, padString, leftSide = false) => {
  if (leftSide) {
    return (
      new Array(padString.length + 1).join(padString) + (value || '')
    ).substr(padString.length * -1);
  }
  return (
    (value || '') + new Array(padString.length + 1).join(padString)
  ).substr(0, padString.length);
};

export const zeroPad = value => strPad(parseInt(value, 10) || '', '00', true);

export const moneyConvert = value => Math.round(value * 100) / 100;

export const camelToDash = s =>
  s.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

export const dashToCamel = s =>
  s.replace(/-([a-z])/g, (a, b) => b.toUpperCase());

export const replaceQuotes = s => s.replace(/&quot;/g, '"');

export const capitalize = string => {
  if (string && isString(string)) {
    var remainingChars = string.slice(1).toLowerCase();
    return string.charAt(0).toUpperCase() + remainingChars;
  }

  return string || '';
};

export const stripTags = string => {
  if (string && isString(string)) {
    return string.replace(/(<([^>]+)>)/gi, '');
  }
  return string;
};

export const localeCompare = (a, b) => (a < b ? -1 : a > b ? 1 : 0);

/**
 * Set or clear the hashkey for an object.
 * @param obj object
 * @param h the hashkey (!truthy to delete the hashkey)
 */
export const setHashKey = (obj, h) => {
  if (h) {
    obj.$$hashKey = h;
  } else {
    delete obj.$$hashKey;
  }
};

export const calculateDistance = (fromLat, fromLng, toLat, toLng) => {
  const toRadians = num => {
    return (Number(num) * Math.PI) / 180;
  };

  const phi1 = toRadians(fromLat),
    phi2 = toRadians(toLat),
    deltaPhi = toRadians(toLat - fromLat),
    deltaLambda = toRadians(toLng - fromLng),
    earthRadius = 6371; // km

  const a =
    Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
    Math.cos(phi1) *
      Math.cos(phi2) *
      Math.sin(deltaLambda / 2) *
      Math.sin(deltaLambda / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadius * c;
};

export const toQueryString = (object, opts = {}) => {
  const { prefix = '', postfix = '' } = opts;
  const string = [];

  for (const key in object) {
    if (object[key] !== undefined) {
      let modifiedKey = key;

      if (prefix) {
        modifiedKey = prefix.concat(modifiedKey);
      }
      if (postfix) {
        modifiedKey = modifiedKey.concat(postfix);
      }

      string.push(
        `${encodeURIComponent(modifiedKey)}=${encodeURIComponent(object[key])}`
      );
    }
  }

  return string.join('&');
};

export const parseQueryString = string => {
  var object = {},
    parts;

  try {
    if (string.indexOf('?') === 0) {
      string = string.substr(1);
    }
    parts = string.split('&');
    for (var i = 0, l = parts.length; i < l; i++) {
      var keyValue = parts[i].split('=');
      if (keyValue[0]) {
        object[decodeURIComponent(keyValue[0])] =
          decodeURIComponent(keyValue[1]) || undefined;
      }
    }
  } catch (e) {
    console.error(e, '/lib/parseQueryString');
    /*RemoveLogging:skip*/
  }
  return object;
};

export const cookie = (name, value, options) => {
  if (typeof value !== 'undefined') {
    // name and value given, set cookie
    options = options || {};
    if (value === null) {
      value = '';
      options.expires = -1;
    }
    var expires = '';
    if (
      options.expires &&
      (typeof options.expires === 'number' || options.expires.toUTCString)
    ) {
      var date;
      if (typeof options.expires === 'number') {
        date = new Date();
        date.setTime(date.getTime() + options.expires * 24 * 60 * 60 * 1000);
      } else {
        date = options.expires;
      }
      expires = `; expires=${date.toUTCString()}`; // use expires attribute, max-age is not supported by IE
    }
    // CAUTION: Needed to parenthesize options.path and options.domain
    // in the following expressions, otherwise they evaluate to undefined
    // in the packed version for some reason...
    var path = options.path ? `; path=${options.path}` : '';
    var domain = options.domain ? `; domain=${options.domain}` : '';
    var secure = options.secure ? '; secure' : '';
    document.cookie = [
      name,
      '=',
      encodeURIComponent(value),
      expires,
      path,
      domain,
      secure
    ].join('');
  } else {
    var cookies, cookie, cookieValue, i;
    if (typeof name !== 'undefined') {
      // only name given, get cookie
      if (document.cookie && document.cookie !== '') {
        cookies = document.cookie.split(';');
        for (i = 0; i < cookies.length; i++) {
          cookie = cookies[i].replace(/^\s+|\s+$/gm, '');
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === `${name}=`) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
    } else {
      cookieValue = [];
      if (document.cookie && document.cookie !== '') {
        cookies = document.cookie.split(';');
        for (i = 0; i < cookies.length; i++) {
          cookie = cookies[i].replace(/^\s+|\s+$/gm, '').split('=');
          cookieValue.push({
            name: cookie[0],
            value: decodeURIComponent(cookie[1])
          });
        }
      }
    }
    return cookieValue;
  }
};

export const stringToBoolean = string => {
  if (!string) {
    return false;
  }

  string = string.toString();

  switch (string.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(string);
  }
};

/**
 * Gets the value at path of object. If the resolved value is undefined, the defaultValue is returned in its place.
 * @param object
 * @param pathArray
 * @param defaultValue
 */
export const get = (object, pathArray, defaultValue) => {
  if (typeof pathArray === 'string') {
    pathArray = pathArray.split('.');
  }
  const result = pathArray.reduce(
    (obj, key) =>
      typeof obj === 'undefined' || obj === null ? undefined : obj[key],
    object
  );
  return typeof result === 'undefined' ? defaultValue : result;
};

export const getFormatted = (
  object,
  pathArray,
  formatter = val => val,
  defaultValue
) => {
  const val = get(object, pathArray);
  return typeof val === 'undefined' ? defaultValue : formatter(val);
};

/**
 * mutates the object removing undefined properties recursively.
 * @param obj
 */
export const removeUndefined = obj => {
  if (typeof obj !== 'undefined') {
    baseRemoveUndefined(obj);
    return obj;
  }
  return undefined;
};

const baseRemoveUndefined = obj => {
  if (Array.isArray(obj)) {
    return obj.map(baseRemoveUndefined.bind(this));
  }
  if (obj !== null && typeof obj === 'object') {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'undefined') {
        delete obj[key];
      } else {
        baseRemoveUndefined(obj[key]);
      }
    });
  }
};

/**
 * converts object params to string
 * @param obj
 * @param isFirstParam
 * @param prefix
 * @returns {string}
 */
export const paramsSerializer = (obj, isFirstParam = true, prefix) => {
  if (!obj) {
    return '';
  }

  const qs = isFirstParam ? '?' : '&';

  return (
    qs +
    Object.keys(obj)
      .map(k => {
        var key = prefix ? `${prefix}[${k}]` : k;
        if (typeof obj[k] === 'object') {
          return paramsSerializer(obj[k], key);
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(obj[k])}`;
      })
      .join('&')
  );
};

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const throttle = (fn, threshhold = 250, context = this) => {
  let last, deferTimer;

  return () => {
    let now = +new Date(),
      args = arguments;

    if (last && now < last + threshhold) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(() => {
        last = now;
        fn.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
};

export const toLowerCase = val =>
  typeof val === 'string' ? val.toLowerCase() : val;
export const toUpperCase = val =>
  typeof val === 'string' ? val.toUpperCase() : val;
export const isTruthyString = val => val === 'true' || val === '1';

export const pick = (obj, ...keys) =>
  keys.reduce(
    (result, key) => ({
      ...result,
      [key]: obj[key]
    }),
    {}
  );

export const omit = (obj, ...keys) =>
  pick(obj, ...Object.keys(obj).filter(key => !keys.includes(key)));

export const hashCode = s => {
  let h = 0,
    l = s.length,
    i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
};

export const compareObjects = (object, base) =>
  transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? compareObjects(value, base[key])
          : value;
    }
  });

export const mergeObjects = (obj1, obj2) => ({ ...obj1, ...obj2 });

export function isDefaultAge(age, ageGroup) {
  return age >= ageGroup.min && age <= ageGroup.max;
}

export function getAgeText(age, ageGroup, config, dataAge) {
  const defaultAge = isDefaultAge(age, ageGroup);
  const driverAgeBetween = get(config, 'driverAge.ageBetween');
  const labelDriverAgeBetween = get(config, 'driverAge.ageBetween.label');
  if (defaultAge && driverAgeBetween) {
    return labelDriverAgeBetween;
  }
  return dataAge;
}

function seededShuffle(array, seed) {
  const rng = s => {
    s = Math.sin(s) * 10000;
    return s - Math.floor(s);
  };

  let m = array.length,
    t,
    i;
  while (m) {
    i = Math.floor(rng(seed++) * m--);
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

export function getCarPaintsArray(colorsObj = {}, regex = /^car\-car\-\d\d$/) {
  const rawData = localStorage.getItem('ct.smartblock.rentals.ct.vehlocsearch');
  const keys = Object.keys(colorsObj).filter(key => regex.test(key));

  if (!rawData || !JSON.parse(rawData).data?.[0]?.[1]?.createdAt) {
    return keys.map(key => colorsObj[key].replace('#', ''));
  }

  const seed = JSON.parse(rawData).data[0][1].createdAt;
  const shuffledKeys = seededShuffle([...keys], seed);
  const carPaintsArr = shuffledKeys.map(key => colorsObj[key].replace('#', ''));
  return carPaintsArr;
}

export const convertObjectIntoInlineStyle = obj => {
  if (isObjHasData(obj)) {
    return Object.entries(obj)
      .map(([key, value]) => `${key}: ${value}`)
      .join('; ');
  }

  return '';
};
