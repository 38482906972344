export const getScript = (
  url,
  successCallback,
  errorCallback,
  timeoutInMs = 3000
) => {
  try {
    var script = document.createElement('script');
    script.type = 'text/javascript';

    var callback = false,
      timeout,
      onSuccess = function () {
        clearTimeout(timeout);
        if (!callback && successCallback) {
          callback = true;
          successCallback();
        }
      },
      onError = function (e) {
        clearTimeout(timeout);
        if (!callback && errorCallback) {
          callback = true;
          errorCallback(e);
        }
      };
    timeout = setTimeout(() => onError('script load timeout'), timeoutInMs);

    if (script.readyState) {
      //IE
      script.onreadystatechange = function () {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          script.onreadystatechange = null;
          onSuccess();
        }
      };
    } else {
      //Others
      script.onload = function () {
        onSuccess();
      };
      script.onerror = function () {
        onError(`script loading error: ${url}`);
      };
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  } catch (e) {
    onError('loadScript function error');
  }
};
