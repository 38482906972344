import { sendError } from 'ct-lib/error/trigger';

/* global ENV_URLS ENV_VERSION */
const URLS = ENV_URLS;
const LOG_URL = URLS.LOGGING.replace('${env}', 'sb');

const params = {
  app: 'CTSB-V2.0',
  ver: ENV_VERSION,
  target: URLS.TARGET,
  action: 'LOADER'
};

let _stream = 'generic';
export const setStream = stream => (_stream = stream);

const logMessage = (msg, desc = '', lvl = 'error') => {
  sendError(LOG_URL, {
    subAction: encodeURIComponent(msg),
    url: encodeURIComponent(window.location.href),
    desc: encodeURIComponent(desc),
    stream: _stream,
    lvl,
    ...params
  });
};

export const logError = (error, desc) => {
  logMessage(error, desc, 'error');
};

export const logInfo = (info, desc) => {
  logMessage(info, desc, 'info');
};
