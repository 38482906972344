import { deepExtend } from '../utils';
import { BrowserHelper } from '../browser';
import { errorType } from '../error';

let _config = {
  error: {
    logURL: '',
    params: {
      // Generic
      lvl: '',
      action: 'APPLICATION',
      subAction: '',
      desc: '',
      // From OTA
      app: '',
      ver: '',
      stream: '',
      target: '',
      elID: '',
      clientID: '',
      // Default
      url:
        window.location.protocol +
        window.location.host +
        window.location.pathname +
        window.location.search,
      dv: BrowserHelper.deviceSummary
    },
    dynamicParams: undefined
  },
  tracking: {
    _LAST_EXIT_FIELD: 'last_field',
    options: {
      url: false,
      environment: '',
      step: 0,
      container: 100,
      maxSizeBuffer: 20,
      maxLength: 10,
      trackEvents: []
    },
    lastTrackEvent: {}
  },
  money: {},
  date: {}
};

export function setConfig(config) {
  deepExtend(_config, config);
  if (config.error && config.error.constants) {
    // Add constants to errorType object
    Object.keys(config.error.constants).forEach(key => {
      errorType[key] = config.error.constants[key];
    });
  }
}

export function getConfig() {
  return _config;
}
