import { get } from 'lodash';
import { deepExtend } from 'ct-lib/utils';

/* global ENV_URLS ENV_VERSION ENV_NAME */
const URLS = ENV_URLS;

export const initStore = () => {
  window.CTStore = deepExtend(
    {
      config: {
        generic: {},
        OTA: {
          clientName: 'generic',
          jsonURL: URLS.OTA,
          jsonhttpsURL: URLS.PAY,
          jsonResURL: URLS.OTA_RES,
          logURL: URLS.LOGGING,
          target: URLS.TARGET,
          environment: ENV_NAME,
          version: ENV_VERSION
        },
        urls: {
          logging: URLS.LOGGING,
          tagging: URLS.TAGGING,
          rentals: {
            voucher: get(URLS, 'VOUCHER.RENTALS')
          }
        },
        startTime: Date.now()
      }
    },
    window.CTStore || {}
  );
};
