import { trigger } from './trigger';

export const debug = (function debug() {
  const allowedMethods = 'error qError warn qWarn info qInfo debug log assert clear count dir dirxml exception group groupCollapsed groupEnd profile profileEnd table time timeEnd trace'.split(
    ' '
  );
  const debugObj = {};
  allowedMethods.forEach(method => {
    debugObj[method] = doDebug.bind(null, method);
  });

  // NOTE: can we remove this?
  debug.profile = () => {
    if (!window.performance) {
      return;
    }
    window.CTPROFILE = {};

    // generates a single namespace for storing the timing.
    function genNS(ns, value) {
      /**
       * regular expression to limit formatting of namespaces
       */
      var nsre = /^([\$\_a-z][\$\_a-z\d]*\.?)+$/i;
      if (!ns.match(nsre)) {
        return;
      }
      ns = ns.split('.');
      var base = window.CTPROFILE;
      for (var i = 0; i < ns.length; i++) {
        base[ns[i]] = base[ns[i]] || {};
        base = base[ns[i]];
        if (i === ns.length - 1) {
          base.timing = value;
        }
      }
    }

    var items = window.performance.getEntriesByType('measure');
    for (var i = 0; i < items.length; ++i) {
      var ns = items[i].name.replace(/:/gi, '.');
      genNS(ns, items[i].duration);
    }
  };

  return debugObj;
})();

function doDebug(method, ...args) {
  if (method.match(/time/gi)) {
    if (!window.performance || !window.performance.mark) {
      return;
    }
    window.performance.mark(
      args[0] + (method.match(/timeEnd/gi) ? '_end' : '')
    );
    if (method.match(/timeEnd/gi)) {
      window.performance.measure(args[0], args[0], `${args[0]}_end`);
    }
    return;
  }

  // Trigger the error
  const setMsgParam = (params, arg) => {
    if (arg instanceof Error) {
      params.desc = arg.message;
    } else {
      params.desc = arg;
    }
  };

  // Add the img to the DOM
  if (method.match('error|info|warn|qInfo|qError|qWarn')) {
    if (method.match('qInfo|qError|qWarn')) {
      method = method.substring(1, method.length).toLowerCase();
    }
    const triggerParams = {
      lvl: method
    };

    if (args.length === 1) {
      // Assume no action or subAction, just msg/error
      setMsgParam(triggerParams, args[0]);
    } else if (args.length === 2) {
      // Assume just subAction & msg/error
      triggerParams.subAction = args[0];
      setMsgParam(triggerParams, args[1]);
    } else if (args.length >= 3) {
      triggerParams.action = args[0];
      triggerParams.subAction = args[1];
      setMsgParam(triggerParams, args[2]);
      // Ignore anything else
    }

    trigger(triggerParams);
  }
}
