import { getConfig } from '../config/ct-lib.config';
import { deepExtend, toQueryString } from '../utils';

export const trigger = function trigger(params = {}) {
  // Set default params
  params.lvl = params.lvl || 'error';
  params.action = params.action || 'APPLICATION';

  const errorConfig = getConfig().error;

  const errorParams = { ...errorConfig.params };
  if (errorConfig.dynamicParams) {
    const dynamicParams = errorConfig.dynamicParams();
    deepExtend(errorParams, dynamicParams);
  }
  deepExtend(errorParams, params);

  sendError(errorConfig.logURL, errorParams);
};

export const sendError = (logURL, errorParams) => {
  if (logURL.includes('ct-errs-dev')) {
    if (errorParams.lvl === 'error') console.error('Error:', errorParams);
  } else {
    logURL = logURL.replace('ct-errs-dev', 'ct-errs');

    const img = new Image();
    const removeImage = () => img.parentNode && img.parentNode.removeChild(img);
    img.setAttribute('style', 'position: absolute; width: 0; height: 0;');
    img.onload = removeImage;
    img.onerror = removeImage;
    const queryStringParams = toQueryString(errorParams);

    img.style.display = 'none';
    img.src = `${logURL}?${queryStringParams}`;
    document.body.appendChild(img);
  }
};
