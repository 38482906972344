export const fetchData = (url, success, fail, timeoutMs = 3000) => {
  const req = new XMLHttpRequest();
  const timeout = setTimeout(() => fail('request timed out'), timeoutMs);
  req.onreadystatechange = () => {
    if (req.readyState === 4) {
      clearTimeout(timeout);
      if (req.status === 200) {
        success(req.responseText);
      } else {
        fail(req.statusText);
      }
    }
  };
  req.open('GET', url);
  req.send();
};

export const fetchDataPost = (url, success, fail, timeoutMs = 3000, body) => {
  const req = new XMLHttpRequest();
  const timeout = setTimeout(() => fail('request timed out'), timeoutMs);
  req.onreadystatechange = () => {
    if (req.readyState === 4) {
      clearTimeout(timeout);
      if (req.status === 200) {
        success(req.responseText);
      } else {
        fail(req.statusText);
      }
    }
  };
  req.open('POST', url);
  req.send(JSON.stringify(body));
};

export const fetchDataAsync = (url, timeoutMs = 3000) => {
  return new Promise((resolve, reject) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const timeout = setTimeout(() => {
      controller.abort();
      reject(new Error('request timed out'));
    }, timeoutMs);

    fetch(url, { signal })
      .then(async response => {
        clearTimeout(timeout);
        if (response.ok) {
          const data = await response.text();
          resolve(data);
        } else {
          reject(new Error(response.statusText));
        }
      })
      .catch(error => {
        clearTimeout(timeout);
        if (error.name === 'AbortError') {
          reject(new Error('request timed out'));
        } else {
          reject(new Error(error.message));
        }
      });
  });
};
