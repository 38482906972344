import { isObject, isArray } from '../utils';
import { exceptionHandler } from './exception-handler';

export const errorType = (function errorType() {
  return {
    map(e, context) {
      let errors =
        (isObject(e) &&
          isObject(e.Errors) &&
          ((isArray(e.Errors) && e.Errors) ||
            (isObject(e.Errors.Error) && e.Errors.Error))) ||
        [];
      const types = {};

      try {
        if (!isArray(errors)) {
          errors = [errors];
        }
        for (var i = errors.length - 1; i >= 0; i--) {
          types[this.getType(errors[i]['@Type'], context)] = errors[i]['#text'];
        }
      } catch (e1) {
        exceptionHandler(e1, 'services/common/error/mapErrors()');
      }
      return types;
    },

    getType(type, context) {
      try {
        var codes = this.CODES[context];
        if (type && codes && codes[type]) {
          return codes[type];
        }
        return type;
      } catch (e) {
        exceptionHandler(e, 'services/common/error/get()');
      }
    },

    contains(errors, type) {
      try {
        if (errors.hasOwnProperty(type)) {
          return errors[type];
        }
      } catch (e) {
        exceptionHandler(e, 'services/common/error/getType()');
      }
    },

    toJSON(errors) {
      try {
        return Object.keys(errors);
      } catch (e) {
        exceptionHandler(e, 'services/common/error/contain()');
      }
    }
  };
})();
