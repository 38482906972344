import { isObject } from '../utils';
import { debug } from './debug';

export const exceptionHandler = function exceptionHandler(
  e,
  subAction,
  action
) {
  if (!(e instanceof Error)) {
    if (isObject(e)) {
      e = new Error(e.message || e.description || '');
    } else {
      e = new Error(e);
    }
  }

  debug.error(action || 'APPLICATION', subAction, e);
};
